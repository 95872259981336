<template>
    <div class="nvra-header">
        <div class="header-section">
            <div class="container conatiner-custom">
                <div class="row login h-100">
                    <div class="col-md-2">
                        <div class="logo-mra">
                            <router-link to="/nvra-login">
                                <img src="/nra/nra-logo-dark.png" class="nra-logo">
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="row justify-content-center">
                            <div class="col-xl-8 col-lg-8 col-md-12 login-inputs mb-3">
                                <login-input url="/nvra-signup"></login-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>NvRA Training Platform</h2>
                    <p>
                        Training solutions to become a certified restaurant employee with the Nevada Restaurant Association
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import LoginInput from "@/views/Pages/LoginInput.vue";

export default {
    components: {
        LoginInput,
    },
    mounted: function () {
        document.querySelector('title').textContent = "NvRA";
    }
}
</script>

<style>
.btnGroup .login-btn{
    min-width: 120px !important;}

 .nvra-header .btn-signup{
     background-color: #FF7500 !important;
 }
</style>

<style scoped>
.mra-login .login-inputs {
    padding-top: 20px;
}

.nra-logo {
    width: auto;
    height: 104px;
}

.header-section {
    border-bottom: 5px solid #FF7500;
}

</style>