<template>
    <div>
        <footer id="footer-main">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                        <ul class="social-link">
                            <li><a href="https://www.facebook.com/nvrestaurants"><i aria-hidden="true" class="fa fa-facebook"></i></a></li>
                            <li><a href="https://twitter.com/nvrestaurants"><i aria-hidden="true" class="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/nevada-restaurant-association"><i aria-hidden="true" class="fa fa-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/nvrestaurants/"><i aria-hidden="true" class="fa fa-instagram"></i></a></li>
                            <li><a href="mailto:info@nvrestaurants.com"><i aria-hidden="true" class="fa fa-envelope"></i></a></li>
                        </ul>
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                        <ul class="footer-link">
                            <li>Nevada Restaurant Association - 2320 Paseo Del Prado, Bldg-B, Ste 103, Las Vegas, NV 89102 Phone:  (702) 878-2313 </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {}
</script>
<style scoped>
ul.social-link, ul.footer-link {
    display: flex;
    list-style: none;
    padding: 0;
}

ul.social-link li {
    margin: 0px 5px;
}

ul.footer-link li {
    margin: 0px 5px;
}
.mra-login .footer-link li {
    color: #d7d7d7;
    font-size: 14px;
}

#footer-main {
    border-top: 5px solid #FF7500;
}

</style>
